// import { MODULE_HOMEPAGE_CONFIGURATION_OF_WEIGHTS } from '@/model/ValueObject/UserPermissionModules'
import { dataHubHpctrApi } from '@/api/datahub'
import NotifyService from '@/services/NotifyService'
import { i18n } from '@/main'

const API_GET_WEIGHTS = '/hp-scoring/weights/get'
const API_CREATE_UPDATE_WEIGHTS = '/hp-scoring/weights/create-update'
const STATUS_CODE_200 = 200

const state = {
  freshnessInputData: null,
  realUsersInputData: null,
  avgTimeSpentInputData: null,
  selectedSites: [],
  disabled: false,
  loading: false
}

const mutations = {
  SET_INPUT_DATA (state, payload) {
    state[payload.input] = payload.data
  },
  SET_SITES (state, siteObjects) {
    state.selectedSites = siteObjects
  },
  SET_LOADING (state, isLoading) {
    state.loading = isLoading
  }
}

const actions = {
  fetchWeights ({ state, commit }) {
    if (state.selectedSites.length === 0) {
      return
    }

    commit('SET_LOADING', true)

    const payload = { brands: state.selectedSites.map(item => item.name) }

    dataHubHpctrApi().post(API_GET_WEIGHTS, JSON.stringify(payload))
      .then(response => {
        commit('SET_LOADING', false)

        const inputs = {
          freshnessInputData: 'weightFreshness',
          realUsersInputData: 'weightRu',
          avgTimeSpentInputData: 'weightAts'
        }

        Object.entries(inputs).forEach(([inputData, key]) => {
          commit('SET_INPUT_DATA', {
            input: inputData,
            data: response.data.length ? Math.min(...response.data.map(obj => obj[key])) : null
          })
        })
      })
      .catch(error => {
        commit('SET_LOADING', false)
        console.error(error)
      })
  },
  saveWeights ({ state, commit }) {
    if (state.selectedSites.length === 0) {
      return
    }
    const brands = state.selectedSites.map(item => item.name)
    const payload = brands.map(brand => ({
      brand: brand,
      weightRu: parseFloat(state.realUsersInputData),
      weightFreshness: parseFloat(state.freshnessInputData),
      weightAts: parseFloat(state.avgTimeSpentInputData)
    }))
    commit('SET_LOADING', true)
    dataHubHpctrApi().post(API_CREATE_UPDATE_WEIGHTS, JSON.stringify(payload))
      .then(response => {
        commit('SET_LOADING', false)
        if (response.status === STATUS_CODE_200) {
          NotifyService.setSuccessMessage(i18n.t('notify.record_was_created'))
        }
      })
      .catch(error => {
        commit('SET_LOADING', false)
        NotifyService.setErrorMessage(i18n.t('notify.save_error'))
        console.error(error)
      })
  },
  updateSites ({ commit, state }, data) {
    const siteObjects = data.map(site => ({ id: site.id, name: site.name }))
    commit('SET_SITES', siteObjects)

    if (state.selectedSites.length === 0) {
      const dataToReset = ['freshnessInputData', 'realUsersInputData', 'avgTimeSpentInputData']
      dataToReset.forEach(model => {
        commit('SET_MODEL', {
          model: model,
          data: null
        })
      })
    }
  }
}

const getters = {
  selectedSites: (state, _, rootState, rootGetters) => {
    // Initially, keep all sites for MVP testing. Adjust later per capabilities.
    const enabledSites = rootGetters['site/all']
    return state.selectedSites.map(site => {
      const foundSite = enabledSites.find(enabledSite => enabledSite.id === site.id)
      return foundSite || site
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
