import CbsApi from '@/api/contentBlockService'
import CoreApi from '@/api/core'
import { dataHubHpctrApi } from '@/api/datahub'

const ACTIVE_ARTICLE_LISTING_API = (siteName, categoryName) => {
  return `ContentBlockGroup/${siteName}/${categoryName}/active-article-listings`
}
const OVERVIEW_FOR_STATUS_PAGE_API = 'Article/overview-for-status-page'
const HPCTR_HISTORICAL_MULTIPLE_API = 'historical-multiple'
const HPCTR_REALTIME_MULTIPLE_API = 'realtime-multiple'

const state = {
  activeArticleListing: null,
  fetchedDataByUuids: null,
  hpCtrHistoricalMultiple: null,
  hpCtrRealtimeMultiple: null
}

const mutations = {
  SET_ACTIVE_ARTICLE_LISTING (state, data) {
    state.activeArticleListing = data
  },
  SET_FETCHED_DATA_BY_UUIDS (state, data) {
    state.fetchedDataByUuids = data
  },
  SET_HP_CTR_HISTORICAL_MULTIPLE (state, data) {
    state.hpCtrHistoricalMultiple = data
  },
  SET_HP_CTR_REALTIME_MULTIPLE (state, data) {
    state.hpCtrRealtimeMultiple = data
  }
}

const actions = {
  async fetchActiveArticleListing ({ commit, dispatch, rootState, rootGetters }) {
    const siteName = rootGetters['site/getSiteNameById'](rootState.contentBlock.hpManagementFilter.site)
    const categoryName = rootGetters['contentBlock/getContentBlockCategoryNameById']

    if (!siteName || !categoryName) {
      console.error('Error: siteName or categoryName is missing')
      return
    }

    commit('TOGGLE_LOADING', true, { root: true })

    try {
      const resp = await CbsApi().get(ACTIVE_ARTICLE_LISTING_API(siteName, categoryName))
      commit('SET_ACTIVE_ARTICLE_LISTING', resp.data)
      dispatch('fetchUuidsFromActiveArticleListing')
    } catch (error) {
      console.error(error)
    } finally {
      commit('TOGGLE_LOADING', false, { root: true })
    }
  },

  async fetchUuidsFromActiveArticleListing ({ state, commit, dispatch }) {
    const entityUuids = state.activeArticleListing.contentBlocks?.flatMap(block =>
      block.activeListing?.map(item => item.entityUuid) || []
    ) || []

    if (entityUuids.length === 0) {
      console.warn('No entity UUIDs found.')
      return
    }

    commit('TOGGLE_LOADING', true, { root: true })

    try {
      const resp = await CoreApi().post(OVERVIEW_FOR_STATUS_PAGE_API, JSON.stringify(entityUuids))
      commit('SET_FETCHED_DATA_BY_UUIDS', resp.data)
      dispatch('fetchHpctrMultiple')
    } catch (error) {
      console.error(error)
    } finally {
      commit('TOGGLE_LOADING', false, { root: true })
    }
  },

  async fetchHpctrMultiple ({ state, commit }) {
    const site = state.activeArticleListing.site || []
    const boxes = state.activeArticleListing.contentBlocks?.map(box => box.identifier) || []

    if (site.length === 0 || boxes.length === 0) {
      console.warn('Site or content blocks are missing')
      return
    }

    const payload = {
      brand: site,
      boxes: boxes
    }

    commit('TOGGLE_LOADING', true, { root: true })

    try {
      const [historicalResp, realtimeResp] = await Promise.all([
        dataHubHpctrApi().post(HPCTR_HISTORICAL_MULTIPLE_API, JSON.stringify(payload)),
        dataHubHpctrApi().post(HPCTR_REALTIME_MULTIPLE_API, JSON.stringify(payload))
      ])
      commit('SET_HP_CTR_HISTORICAL_MULTIPLE', historicalResp.data)
      commit('SET_HP_CTR_REALTIME_MULTIPLE', realtimeResp.data)
    } catch (error) {
      console.error(error)
    } finally {
      commit('TOGGLE_LOADING', false, { root: true })
    }
  }
}

const getters = {
  /**
   * Returns a function that retrieves the Click-Through Rate (CTR)
   * for a given box and position based on the selected device type.
   *
   * - If `mainCtrSelectValue` is 'total', returns the overall CTR.
   * - If 'desktop', returns the desktop-specific CTR.
   * - If 'mobile', returns the mobile-specific CTR.
   * - If `mainCtrSelectValue` is invalid or missing, falls back to the overall CTR.
   *
   * @param {Object} state - Vuex state.
   * @returns {Function} - A function that takes `box`, `position`, and `mainCtrSelectValue` as parameters.
   */
  getHistoricalCtr: (state) => (box, position, mainCtrSelectValue) => {
    // Find the data for the given box
    const selectedData = state.hpCtrHistoricalMultiple?.find(item => item.box === box)

    // Find the specific result for the given position
    const selectedResult = selectedData?.results?.find(item => item.position === position)

    // Return "No Data" icon if no result is found
    if (!selectedResult) {
      return '<i title="No data" class="fas fa-ban"></i>'
    }

    // Determine CTR value based on selected device type
    const ctrValue = {
      total: selectedResult.ctr, // Overall CTR
      desktop: selectedResult.ctrPerDevice?.desktop, // Desktop-specific CTR
      mobile: selectedResult.ctrPerDevice?.mobile // Mobile-specific CTR
    }[mainCtrSelectValue] ?? selectedResult.ctr // Default to overall CTR if selection is invalid

    // Return "No Data" icon if CTR value is not found
    if (ctrValue === undefined || ctrValue === null) {
      return '<i title="No data" class="fas fa-ban"></i>'
    }

    // Convert to percentage and return formatted CTR value
    return (ctrValue * 100).toFixed(2) + '%'
  },

  /**
   * Retrieves real-time Click-Through Rate (CTR) or related metrics for a given box and position.
   *
   * - If `itemType` is 'recirculation', returns recirculation rate as a percentage.
   * - If `itemType` is 'readingTime', returns the total reading time in HH:MM:SS format.
   * - If `itemType` is 'hpScore', calculates and returns the homepage score.
   * - If `itemType` is 'ctr', returns the CTR based on the selected device type.
   *
   * If data is missing or invalid, returns a "No Data" icon.
   *
   * @param {Object} state - Vuex state.
   * @returns {Function} - A function that takes `box`, `position`, `mainCtrSelectValue`, and `itemType` as parameters.
   */
  getRealtimeCtr: (state) => (box, position, mainCtrSelectValue, itemType) => {
    const NO_DATA_ICON = '<i title="No data" class="fas fa-ban"></i>'

    // Define allowed item types
    const itemTypeValues = ['recirculation', 'readingTime', 'hpScore', 'ctr']

    // Find the data for the given box
    const selectedData = state.hpCtrRealtimeMultiple?.find(item => item.box === box)
    const selectedResult = selectedData?.results?.find(item => item.position === position)

    // Return "No Data" icon if no result is found or itemType is invalid
    if (!selectedResult || !itemTypeValues.includes(itemType)) {
      return NO_DATA_ICON
    }

    // Handle recirculation (1 - bounceRate) converted to percentage
    if (itemType === 'recirculation') {
      return ((1 - selectedResult.bounceRate) * 100).toFixed(2) + '%'
    }

    // Handle reading time, converting total seconds to HH:MM:SS format
    if (itemType === 'readingTime') {
      const totalSeconds = selectedResult.readingTime
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const seconds = totalSeconds % 60
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    // Handle homepage score calculation
    if (itemType === 'hpScore') {
      const selectedDataHistorical = state.hpCtrHistoricalMultiple?.find(item => item.box === box)
      const selectedResultHistorical = selectedDataHistorical?.results?.find(item => item.position === position)

      if (!selectedResultHistorical) {
        return NO_DATA_ICON
      }

      const contentBlockFromListing = state.activeArticleListing.contentBlocks.find(item => item.identifier === box)
      const importance = contentBlockFromListing?.activeListing.find(item => item.position === position)?.importance

      const ctrArticle = selectedResult.ctr
      const ctrPosition = selectedResultHistorical.ctr

      if (!ctrArticle || !ctrPosition || !importance) {
        return NO_DATA_ICON
      }

      return ((ctrArticle / ctrPosition) * importance).toFixed(2)
    }

    // Handle CTR selection based on mainCtrSelectValue (total, desktop, or mobile)
    if (itemType === 'ctr') {
      const ctrValue = {
        total: selectedResult.ctr,
        desktop: selectedResult.ctrPerDevice?.desktop,
        mobile: selectedResult.ctrPerDevice?.mobile
      }[mainCtrSelectValue] ?? selectedResult.ctr

      return ctrValue !== undefined && ctrValue !== null
        ? (ctrValue * 100).toFixed(2) + '%'
        : NO_DATA_ICON
    }

    // Fallback: return "No Data" icon
    return NO_DATA_ICON
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
